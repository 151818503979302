var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{attrs:{"fixed-tabs":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"ordemArea",attrs:{"to":`/areas/${_vm.$route.params.id}/editar`}},[_vm._v(" Area ")]),_c('tab-rotas-box',{staticClass:"ordemBox",attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao']('listar.areas.convenios'),"to":`/areas/${_vm.$route.params.id}/box`}}),_c('tab-rotas-tabelas',{staticClass:"ordemTabela",attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao']('listar.areas.tabelas'),"to":`/areas/${_vm.$route.params.id}/tabela`}}),_c('v-tab',{staticClass:"ordemConvenio"},[_vm._v(" Convênio ")]),_c('tab-rotas-tipos-pagamentos',{staticClass:"ordemPagamento",attrs:{"to":`/areas/${_vm.$route.params.id}/tipos-pagamentos`,"temPermissao":_vm.$store.getters['usuario/temPermissao']('listar.areas.tipospagamentos')}})],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item'),_c('v-tab-item'),_c('v-tab-item'),_c('v-tab-item',[_c('v-card-actions',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('btn-adicionar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                                    'inserir.convenios'
                                ),"to":`../${_vm.$route.params.id}/convenio/adicionar`}})],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.totalRegistros,"headers":_vm.cabecalho,"items":_vm.items,"options":_vm.paginacao,"footer-props":{
                                'items-per-page-options': [10, 20, 50],
                            }},on:{"update:options":[function($event){_vm.paginacao=$event},_vm.carregarConvenios]},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('table-btn-mostrar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                                        'mostrar.convenios'
                                    ),"href":`/areas/${item.area_id}/convenio/${item.id}/mostrar`}}),_c('table-btn-editar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                                        'editar.convenios'
                                    ),"href":`/areas/${item.area_id}/convenio/${item.id}/editar`}})]}}],null,true)})],1)],1)],1),_c('v-tab-item')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }